import { useTranslation } from 'react-i18next';
import './footer.scss';

const ImageLinks = (props) => {
	return (
			<a href={props.href}>
				<img src={props.image} alt={props.alt} />
			</a>
	)
}

const Footer = () => {
	const { t } = useTranslation();
  return (
    <div className = "Footer">
			<h1>{t('footer.title')}</h1>
			<h2>{t('footer.email')} <a href="mailto:jonasz.potoniec@gmail.com" >jonasz.potoniec@gmail.com</a></h2>
			<div className="FooterImageLinksContainer">
				<ImageLinks
						image={require("../media/logos/telegram.svg").default}
						alt="telegram logo"
						href="tg://resolve?domain=jonaszpotoniec"
				/>
				<ImageLinks
						image={require("../media/logos/linkedin.png").default}
						alt="telegram logo"
						href="https://www.linkedin.com/in/jonasz-potoniec-b5a45917a/"
				/>
			</div>
    </div>
  );
}

export default Footer;
 
