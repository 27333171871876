import './projects.scss';
import { useTranslation } from 'react-i18next';

const Project = (props) => {
	const { t } = useTranslation();
	return (
		<div className="Project">
			<picture>
				{
					props.imageWebp ?
					<source srcSet={props.imageWebp} type="image/webp" />
					: <></>
				}
				<img src={props.image} className={props.zoom ? "zoom" : ""} alt={props.alt} />
			</picture>
			<div>
				<h2>{t(`projects.${props.translation}.title`)}</h2>
				<h3 dangerouslySetInnerHTML={{ __html: t(`projects.${props.translation}.subtitle`)}}></h3>
				{
					props.github ?
							<iframe src={`https://ghbtns.com/github-btn.html?${props.github}&type=star&count=true&size=large`} frameBorder="0" scrolling="0" width="170" height="30" title="GitHub" className="github"></iframe>
							: <></>
				}
				<span dangerouslySetInnerHTML={{ __html: t(`projects.${props.translation}.description`) }} >
				</span>
			</div>
		</div>
	)
}

const ImageLinks = (props) => {
	return (
		<a href={props.href}>
			<img src={props.image} alt={props.alt} />
		</a>
	)
}

const Projects = () => {
	const { t } = useTranslation();
  return (
    <div className = "Projects">
			<h1>{t("projects.title")}</h1>
			<Project
				image  = {require("../media/projects/sspl.jpg").default}
				imageWebp  = {require("../media/projects/sspl.webp").default}
				alt = "sspl"
				zoom = {true}
				translation = "sspl"
				title = "Strona samorządu politechniki łódzkiej"
				subtitle = "stack: react, gatsby, wordpress, scss, graphQl" >
				Byłem jednym z członków zespołu tworzącego nową stronę samorządu politechniki łódzkiej. Dla ułatwienia dodawania treści backend wykonany jest w wordpressie z własnymi polami. Frontend zrobiony jest od podstaw w gatsby. Dane są pobierane z użyciem graphQl. 
			</Project>
			<Project
				image  = {require("../media/projects/schowane_za_rogiem.jpg").default}
				imageWebp  = {require("../media/projects/schowane_za_rogiem.webp").default}
				alt = "schowane_za_rogiem"
				zoom = {true}
				translation = "schowane_za_rogiem"
				title = "Schowane za rogiem"
				subtitle = "stack: react, expressjs, mysql, nginx" >
				Jest to projekt społeczny, którego celem była pomoc małym sklepom. Razem z zespołem chodziliśmy po mieście w poszukiwaniu zapomnianych sklepików osiedlowych. Następnie wpisywaliśmy ich dane do systemu, który generował stronę internetową. Oprócz tego dostępna była wyszukiwarka sklepów w okolicy z podziałem na kategorie.
Ja odpowiadałem za całą kwestię techniczą (serwery i programowanie) 
			</Project>
			<Project
					image  = {require("../media/projects/nearme.jpg").default}
					imageWebp  = {require("../media/projects/nearme.webp").default}
					alt = "nearme"
					zoom = {true}
					translation = "nearme" >
			</Project>
			<Project
					image  = {require("../media/projects/snake.jpg").default}
					imageWebp  = {require("../media/projects/snake.webp").default}
					alt = "Snake Battle Royale"
					zoom = {true}
					translation = "snake"
					title = "Snake Battle Royale"
					subtitle = "stack: react, nodejs, Socket.IO" >
			</Project>
			<Project
				image  = {require("../media/projects/wsl.jpg").default}
				imageWebp  = {require("../media/projects/wsl.webp").default}
				alt = "wsl"
				zoom = {true}
				translation = "wsl"
				title = "WSL Manager"
				subtitle = "stack: vue, electron" 
				github = "user=JonaszPotoniec&repo=WSL-manager"
				>
				Jest to mój najpopularniejszy projekt na githubie. Program pozwala na proste przełączanie między systemami w Windows Subsystem for Linux.
			</Project>
			<Project
					image  = {require("../media/projects/chip8.jpg").default}
					imageWebp  = {require("../media/projects/chip8.webp").default}
					alt = "Chip-8"
					zoom = {true}
					translation = "chip-8"
					title = "Emulator Chip-8"
					subtitle = "stack: c, c++, PlatformIO, GD32&nbsp;(Risc&nbsp;V)"
			>
				Jest to emulator/interpreter Chip-8 przygotowany na mikrokontroler longan nano bazujący na procesorze GD32 (odpowiednik STM32). Projekt wciąż nie jest całkowicie ukończony ale obsługuje już wszystkie instrukcje procesora i pozwala na granie w gry wczytane z karty pamięci.
			</Project>
			<Project
				image  = {require("../media/material_icons/precision_manufacturing_black_24dp.svg").default}
				alt = "bots"
				zoom = {false}
				translation = "bots"
				title = "Boty do komunikatorów"
				subtitle = "stack: nodejs, discordjs, telegram-bot-api" 
				>
				<ul>
					<li>
						bot na discord ściąga najnowszy plan zajęć ze strony politechniki łódzkiej, znajduje stronę porządanej grupy, konwertuje ją do png i wysyła na odpowiedni kanał
					</li>
					<li>
						bot na telegram odsyłający wiadomoś napisaną małą   czcionką link
					</li>
					<li>
						bot na telegram ściągający najnowsze zdjęcia z reddita link
					</li>
				</ul>
			</Project>
			<Project
				image  = {require("../media/material_icons/translate_black_24dp.svg").default}
				alt = "translate"
				zoom = {false}
				translation = "translations"
				title = "Tłumaczenia"
				subtitle = "W wolnym czasie lubię wspierać projekty open source pomagając w lokalizacji."
				>
				Przetłumaczyłem otwarty klient spotify Spot na język polski
				<br/>
				Pomagałem przy tłumaczeniu: 
				<ul>
					<li>
						dokumentacji mikrokontrolera bl602 
					</li>
					<li>
						wybranych aplikacji ubuntu touch
					</li>
					<li>
						wybranych aplikacji elementary os
					</li>
				</ul>
			</Project>
    
			<div className="ImageLinksContainer">
				<h2>{t("projects.more")}</h2>
				<div>
					<ImageLinks 
						image  = {require("../media/logos/GitHub.svg").default}
						href  = "https://github.com/JonaszPotoniec"
						alt = "github"
					/>
					<ImageLinks 
						image  = {require("../media/logos/codepen.svg").default}
						href  = "https://codepen.io/jonaszpotoniec/"
						alt = "codepen"
					/>
				</div>
			</div>
    </div>
  );
}

export default Projects;
 
