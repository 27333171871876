import './styles/App.css';
import Header from './components/header.js';
import Language from './components/language';
import ContactMe from './components/contactMe';
import WhoAmI from './components/whoAmI.js';
import Projects from './components/projects.js';
import Footer from './components/footer';

function App() {
	return (
		<div className="App">
			<Header/>
			<Language/>
			<ContactMe/>
			<WhoAmI/>
			<Projects/>
			<Footer/>
		</div>
	);
}

export default App;
