import { useTranslation } from 'react-i18next';
import './header.scss';
import me from '../media/me.svg';


const Header = () => {
	const { t } = useTranslation();

  return (
    <header className="Header">
			<span>
				{t('header.myNameIs')}<br/>
				<h1>Jonasz Potoniec</h1>
				{t('header.icreatewebsites')}
			</span>
			<img  
				src={me}
				alt="me"
			/>
    </header>
  );
}

export default Header;
