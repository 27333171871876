import './whoAmI.scss';
import { useTranslation } from 'react-i18next';

const WhoAmIElement = (props) => {
	const { t } = useTranslation();
	return (
		<div className="WhoAmIElement">
			<img src={props.icon} alt={props.alt} />
			<h2>{/*props.title*/ t(`whoAmI.${props.translation}.title`)}</h2>
			<p>
				{/*props.children*/ t(`whoAmI.${props.translation}.description`)}
			</p>
		</div>
	)
}

const WhoAmI = () => {
	const { t } = useTranslation();
  return (
    <div className = "WhoAmI">
			<h1>{t(`whoAmI.title`)}</h1>
			<div>
				<WhoAmIElement 
					title = "studentem"
					icon  = {require("../media/material_icons/school_white_24dp.svg").default}
					alt = "studentem"
					translation = "student"
					>
					studiuję informatykę na wydziale elektronicznym na politechnice łódzkiem
				</WhoAmIElement>
				<WhoAmIElement 
					title = "wolontariuszem"
					icon  = {require("../media/material_icons/groups_white_24dp.svg").default}
					alt = "wolontariuszem"
					translation = "wolontariuszem"
					>
					należę do samorządu studenckiego oraz chętnie wspieram projekty open source
				</WhoAmIElement>
				<WhoAmIElement 
					title = "pasjonatem"
					icon  = {require("../media/material_icons/avr.svg").default}
					alt = "pasjonatem"
					translation = "pasjonatem"
					>
					lubię eksperymentować, programować i próbować nowe rzeczy w czasie wolnym
				</WhoAmIElement>
			</div>
    </div>
  );
}

export default WhoAmI;
 
