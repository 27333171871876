import './contactMe.scss';
import email_black_24dp from '../media/material_icons/email_black_24dp.svg'

const ContactMe = () => {
	return (
			<img
					className="contactMe"
					src={email_black_24dp}
					alt="contact me"
					onClick={() => document.body.scrollIntoView(false)}
			>
			</img>
	);
}

export default ContactMe;

