import { useTranslation } from 'react-i18next';
import { useState } from 'react'
import './language.scss';

const Language = () => {
	const [ , i18n ] = useTranslation();
	const [ languages ] = useState(["en", "pl"]);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};
	
	return (
		<div className="language">
			{
				languages.map((e) => (
					<div className={e} key={e} onClick={() => changeLanguage(e)}>{e}</div>
									))
			}
		</div>
	);
}

export default Language;
 
